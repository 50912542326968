/*
 * Bootstrap overrides https://getbootstrap.com/docs/5.1/customize/sass/
 * All values defined in bootstrap source
 * https://github.com/twbs/bootstrap/blob/v5.1.3/scss/_variables.scss can be overwritten here
 * Make sure not to add !default to values here
 */

 $blue:    #0E4E9D;
 $indigo:  #6610f2;
 $purple:  #6f42c1;
 $pink:    #d63384;
 $red:     #AE0C0B;
 $orange:  #f8765f;
 $yellow:  #FDC700;
 $green:   #2fb380;
 $teal:    #20c997;
 $cyan:    #3783D4;

 $primary:       $blue;
 $info:          $cyan;
 $warning:       $yellow;
 $danger:        $red;

 #page-heading, h2
 {
    color: $blue;
 }

 .table {
    --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.03);
    box-shadow: none;
  }

 .table td:not(.text-end) a {
    text-decoration: none;
    color: #000;
  }

 
  .row-md.jh-entity-details 
  {
    column-gap: 0 !important;
  }

  .row-md.jh-entity-details dt 
  {
    text-align: left !important;
    border-bottom: 1px solid #eee;
    padding: 12px 0 !important;
    margin: 0 !important;
  }

  .row-md.jh-entity-details dd 
  {
    border-bottom: 1px solid #eee;
    padding: 12px 0 0 20px !important;
    margin: 0 !important;
    font-size: 15px;
  }

  input[type="checkbox"] {
    margin-top: 9px;
}

  .row-md.jh-entity-details dt.buttons, .row-md.jh-entity-details dd.buttons
  {
    border-bottom: none !important;
  }

  form[name="editForm"] label, .row-md.jh-entity-details dt 
  {
    font-weight: 700;
    font-size: 15px;
  }

 #logo-container
 {
    width: 100%;
    text-align: center;
 }

 #logo-login
 {
    width: 200px;
    margin: auto;
 }

 #login-container
 {
    width: 360px;
    margin: 0 20px;
 }

 #lost-psw
 {
    font-size: 14px;
 }

 #logo-topbar
 {
    width: 159px;
 }

 .navbar.bg-light {
    background-color: #fff !important;
    border-bottom: 1px solid #f8f9fa;
}

#sidebar
{
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: #0E4E9D !important;
    min-height: 100%;
}

#sidebar a
{
    color: #fff;
}

ul#sidebar-menu
{
    padding: 0;
}

ul#sidebar-menu li
{
    list-style: none; 
    padding: 0;
}

ul#sidebar-menu li .ng-fa-icon
{
    width: 18px;
    display: inline-block;
    text-align: center;
}

ul#sidebar-menu li a
{
    font-size: 14px;
    padding: 10px 30px;
    display: block;
    position: relative;
}


ul#sidebar-menu li a.nav-link.active
{
    background-color: #FFFFFF;
    color: #0E4E9D;
}

ul#sidebar-menu > li:first-child a.nav-link.active
{
      background-color: #EFF7FF;
}

ul#sidebar-menu li a:has(+ .submenu):after 
{
    position: absolute;
    top: 13px;
    right: 15px;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("/content/images/arrow-menu-blu.svg");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
}

ul#sidebar-menu li a:has(+ .submenu.show):after  
{
    transform: rotate(180deg);
}

ul#sidebar-menu li .submenu a 
{
    background-color: #3783D4;
}

.jh-card:has(#bkg-home) {
  background-color: #EFF7FF;
  margin-top: 0;
}

#bkg-home .card-header {
  background-color: transparent;
}

#bkg-home .card, #bkg-home .card-header {
  border: none;
}


@media (min-width: 576px) 
{
    .px-sm-2 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}